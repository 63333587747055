import React, { useState } from 'react';
import { IMAGES } from '../constants/theme';
import { HashLink } from 'react-router-hash-link';
import LocalizationContext from "../context/LocalizationContext";

const pricingBlog = [
    {id: 1, rate: '59', title: 3, text1: 6, text2: 9, text22: 22, text3: 23, text4: 12, text5: 15},
    {id: 2, rate: '49', title: 4, prime: 'premium', text1: 7, text2: 10, text22: 22, text3: 23, text4: 13, text5: 16},
    {id: 3, rate: '85', title: 5, text1: 8, text2: 11, text3: 23, text4: 14, text5: 17},
];

const Pricing = () => {
    const [hoverEffect, setHoverEffect] = useState(1);
    const { t } = React.useContext(LocalizationContext);
    return (
        <>
            <div className="page-content bg-white">   
                <section style={{backgroundImage: "url("+ IMAGES.BgImage1 +")"}}>
                    <div className="container">
                    <div className="section-head">
                        <h2 className="title">{t("5:1")}</h2>
                        </div> 
                        <div className="section-head">
                        <span>{t("5:2")}</span>
                        </div>    
                        <div className="row">
                            {pricingBlog.map((data, ind)=>(
                                <div className="col-lg-4 col-md-6 m-b30" key={ind}>
                                    <div className={`pricingtable-wrapper box-hover style-1 ${ ind === hoverEffect ? 'active' : '' }`}
                                        onMouseEnter={()=>setHoverEffect(ind)}
                                    >
                                        <div className="pricingtable-inner">
                                            <div className={`pricingtable-title ${data.prime}`} >{t(`5:${data.title}`)}</div>
                                            
                                            <div className="pricingtable-price">
                                                <h2 className="pricingtable-bx text-primary">€{data.rate}{data.id === 3 ? null : <small>/ {t("5:18")}</small>}</h2>
                                                {data.id === 1 ? null : data.id === 2 ? <p>{t("5:20")}</p> : <p>{t("5:19")}</p>}
                                            </div>
                                            <ul className="pricingtable-features">
                                            {data.id === 3 ? null : <li>{t(`5:${data.text1}`)}</li>}
                                                <li>{t(`5:${data.text2}`)}</li>
                                            {data.id === 3 ? null : <li>{t(`5:${data.text22}`)}</li>}
                                                <li>{t(`5:${data.text3}`)}</li>
                                                <li>{t(`5:${data.text4}`)}</li>
                                                <li>{t(`5:${data.text5}`)}</li>
                                            </ul>
                                            <div className="pricingtable-footer"> 
                                                <HashLink to={"#contact"} className="btn btn-primary btn-skew"><span>{t("5:21")}</span></HashLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}                        
                        </div>
                    </div>
                </section>           
            </div>
        </>
    );
};

export default Pricing;